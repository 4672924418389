import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Container, Box, Typography, TextField, Button, Link, Avatar, CssBaseline, FormControlLabel,
  Checkbox, Grid, LinearProgress, FormLabel,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from './footer';
import { userLoggedIn } from '../store/user-slice';

const theme = createTheme();

export default function login() {
  const dispatch = useDispatch();
  const loadingStatus = useSelector((state) => state.user.status);
  const loginStatus = useSelector((state) => state.user.profile.loggedIn);
  const errorResponse = useSelector((state) => state.user.error || 'Server Error');

  const [error, setError] = useState(false);

  useEffect(() => {
    if (loadingStatus === 'error') setError(true);
  }, [loadingStatus]);

  useEffect(() => {
    if (loginStatus) {
      history.push({ pathname: '/' });
    }
  }, [loginStatus]);

  const history = useHistory();

  const handleLogin = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    dispatch(userLoggedIn({
      email: data.get('email'),
      password: data.get('password'),
    }));
    // history.push({ pathname: '/', state: { isLoggedIn: true } });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100vh', bgcolor: '#eaeff1' }}>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
                {loadingStatus === 'error' && (
                  <FormLabel error={error} component="legend">{errorResponse}</FormLabel>
                )}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                {loadingStatus === 'loading' && (
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                  </Box>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link component={RouterLink} to="/reset" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link component={RouterLink} to="/signup" variant="body2">
                      Dont have an account? Sign Up
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
          <Box component="footer" sx={{ mt: 8, mb: 4 }}>
            <Footer />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
