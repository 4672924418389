import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Menu, MenuItem, ListItemText, Avatar, Tooltip, Fade, IconButton,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import InventoryIcon from '@mui/icons-material/Inventory';
import PaymentsIcon from '@mui/icons-material/Payments';
import { deepOrange } from '@mui/material/colors';
import { userLoggedOut } from '../store/user-slice';

const menuItems = [
  { label: 'Manage Account', component: <ManageAccountsIcon fontSize="small" /> },
  { label: 'Control Center', component: <ControlPointDuplicateIcon fontSize="small" /> },
  { label: 'Order History', component: <InventoryIcon fontSize="small" /> },
  { label: 'Billing History', component: <PaymentsIcon fontSize="small" /> },
  { label: 'Logout', component: <LogoutIcon fontSize="small" /> },
];

export default function popovermenu() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.profile);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpenUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, item) => {
    if (item === 'Logout') dispatch(userLoggedOut());
    setAnchorEl(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleOpenUserMenu} color="inherit" sx={{ p: 0.5 }}>
        <Tooltip
          title={`${user.firstName} ${user.lastName}`}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
        >
          <Avatar
            alt={`${user.firstName} ${user.lastName}`}
            sx={{ bgcolor: deepOrange[500] }}
          />
        </Tooltip>
      </IconButton>
      <Menu
        sx={{ mt: '40px' }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleCloseUserMenu}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item.label}
            onClick={(event) => handleMenuItemClick(event, item.label)}
          >
            <ListItemIcon>
              {item.component}
            </ListItemIcon>
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
