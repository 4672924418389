import httpClient from './http-common';

const baseURL = 'https://backend.smartseciot.com:9010/api/v1';

const parseErrorResponse = (err) => {
  // Error
  if (err.response) {
    // The request was made and the server responded with a status code
    return err.response.data.error;
  }

  if (err.request) {
    // The request was made but no response was received
    return err.request.response;
  }
  return err.message;
};

/**
 * Setup new user account
 * @param {*} payload
 * @returns
 */
const postUser = async (payload) => {
  const config = {
    method: 'post',
    mode: 'cors',
    url: `${baseURL}/signup`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: payload,
  };

  try {
    const response = await httpClient(config);

    return { status: response.status, data: response.data.data };
  } catch (err) {
    return { status: 'error', data: JSON.stringify(err) };
  }
};

/**
 * Login User
 * @param {*} payload
 * @returns
 */
const postLogin = async (payload) => {
  const config = {
    method: 'post',
    mode: 'cors',
    baseURL,
    url: '/signin',
    headers: {
      'Content-Type': 'application/json',
    },
    data: payload,
  };

  try {
    const response = await httpClient(config);

    return response.data;
  } catch (err) {
    return { status: 'error', data: parseErrorResponse(err) };
  }
};

const assetByUser = async (payload) => {
  const config = {
    method: 'get',
    mode: 'cors',
    url: `${baseURL}/asset/customer/${payload.userId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: payload.accessToken,
    },
  };

  try {
    const result = await httpClient(config);
    return result.data;
  } catch (err) {
    return { status: 'error', data: JSON.stringify(err) };
  }
};

const getGatewayByAsset = async (payload) => {
  const config = {
    method: 'get',
    mode: 'cors',
    url: `${baseURL}/device/asset/${payload.assetId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: payload.accessToken,
    },
  };

  try {
    const response = await httpClient(config);

    return { status: response.status, data: response.data };
  } catch (err) {
    return { status: 'error', data: JSON.stringify(err) };
  }
};

const getDevicesByCustomer = async (payload) => {
  const config = {
    method: 'get',
    mode: 'cors',
    url: `${baseURL}/device/customer/${payload.customerId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: payload.accessToken,
    },
  };

  try {
    const response = await httpClient(config);

    return { status: response.status, data: response.data };
  } catch (err) {
    return { status: 'error', data: JSON.stringify(err) };
  }
};

const getTimeseriesKeys = async (payload) => {
  const config = {
    method: 'get',
    mode: 'cors',
    url: `${baseURL}/timeseries/${payload.entityType}/${payload.entityId}/keys`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: payload.accessToken,
    },
  };

  try {
    const response = await httpClient(config);

    return { status: response.status, data: response.data };
  } catch (err) {
    return { status: 'error', data: JSON.stringify(err) };
  }
};

const getLatestTimeseries = async (payload) => {
  const config = {
    method: 'get',
    mode: 'cors',
    url: `${baseURL}/timeseries/${payload.entityType}/${payload.entityId}/values?keys=SecurityEnabled,IntrusionDetected`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: payload.accessToken,
    },
  };

  try {
    const response = await httpClient(config);

    return { status: response.status, data: response.data };
  } catch (err) {
    return { status: 'error', data: JSON.stringify(err) };
  }
};

const postGateWayStatus = async (payload) => {
  const config = {
    method: 'post',
    mode: 'cors',
    url: `${baseURL}/rpc/${payload.entityId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: payload.accessToken,
    },
    data: {
      method: payload.method,
      params: payload.params.label,
    },
  };

  try {
    const response = await httpClient(config);

    return { status: response.status, data: response.data };
  } catch (err) {
    return { status: 'error', data: JSON.stringify(err) };
  }
};

const BackendService = {
  postUser,
  postLogin,
  assetByUser,
  getGatewayByAsset,
  getDevicesByCustomer,
  getTimeseriesKeys,
  getLatestTimeseries,
  postGateWayStatus,
};

export default BackendService;
