import React from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Home from './index';
import Login from './login';
import Signup from './signup';

export default function routes() {
  const user = useSelector((state) => state.user.profile);
  const isLoggedIn = (user && user.loggedIn) ? user.loggedIn : false;
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          {isLoggedIn ? <Home /> : <Login />}
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/signup">
          <Signup />
        </Route>
      </Switch>
    </Router>
  );
}
