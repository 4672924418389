import React from 'react';
import { useSelector } from 'react-redux';
import {
  Card, CardMedia, CardContent, Grid,
  Typography, CardActions, IconButton,
} from '@mui/material';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
// import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import Battery80Icon from '@mui/icons-material/Battery80';

export default function devicelist() {
  const devices = useSelector((state) => state.devices.devices);

  const cardContent = devices
    // .filter((e) => (e.type !== 'gateway'))
    .map((item) => (
      <Grid key={item.id} item xs={3}>
        <Card sx={{ maxWidth: 345 }}>
          <CardMedia
            component="img"
            alt="My Device"
            height="140"
            image="../images/smarthome.jpg"
          />
          <CardContent>
            <Typography style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography gutterBottom variant="h5" component="div">
                {item.label ? item.label : item.name}
              </Typography>
              <Battery80Icon color="primary" style={{ marginLeft: 'auto' }} fontSize="small" />
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {item.deviceData?.configuration.type}
            </Typography>
          </CardContent>
          <CardActions>
            <IconButton>
              <AlarmOnIcon fontSize="large" color={item.status === 'on' ? 'success' : 'disabled'} />
            </IconButton>
            <IconButton>
              <SettingsIcon fontSize="large" color="primary" />
            </IconButton>
            <IconButton>
              <InfoIcon fontSize="large" color="primary" />
            </IconButton>
          </CardActions>
        </Card>
      </Grid>
    ));

  return (
    <Grid container spacing={{ xs: 2, md: 3 }} direction="row">
      {cardContent}
    </Grid>
  );
}
