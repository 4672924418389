import React from 'react';
import { Provider } from 'react-redux';
// import { height } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Index from './components';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import Routes from './components/routes';
import configureStore from './store';

const store = configureStore;
const persistor = persistStore(store);
const theme = createTheme();

const App = function () {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
