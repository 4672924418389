import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import backendService from '../services/backend-services';

const initialState = {
  assets: [],
  gateways: [],
  devices: [],
  gatewayStatus: {
    pausePolling: true,
    IntrusionDetected: 0,
    SecurityEnabled: 0,
  },
};

export const assetByUser = createAsyncThunk('user/assets', async (payload, thunkAPI) => {
  const response = await backendService.assetByUser(payload);
  thunkAPI.dispatch(
    gatewaysByAsset(
      {
        assetId: response.id,
        accessToken: payload.accessToken,
      },
    ),
  );
  thunkAPI.dispatch(
    devicesByCustomer(
      {
        customerId: response.userId,
        accessToken: payload.accessToken,
      },
    ),
  );
  return response;
});

export const gatewaysByAsset = createAsyncThunk('user/gateways', async (payload, thunkAPI) => {
  const response = await backendService.getGatewayByAsset(payload);
  thunkAPI.dispatch(
    // timeseriesKeys(
    latestTimeseries(
      {
        entityType: 'DEVICE',
        entityId: response.data.deviceId,
        accessToken: payload.accessToken,
      },
    ),
  );
  return response;
});

export const devicesByCustomer = createAsyncThunk('user/devices', async (payload) => {
  const response = await backendService.getDevicesByCustomer(payload);
  return response;
});

export const timeseriesKeys = createAsyncThunk('user/TimeseriesKeys', async (payload, thunkAPI) => {
  const response = await backendService.getTimeseriesKeys(payload);
  thunkAPI.dispatch(
    latestTimeseries(
      {
        entityType: payload.entityType,
        entityId: payload.entityId,
        keys: response,
        accessToken: payload.accessToken,
      },
    ),
  );
  return response;
});

export const latestTimeseries = createAsyncThunk('user/LatestTimeseries', async (payload) => {
  const response = await backendService.getLatestTimeseries(payload);
  return response;
});

export const postGateWayStatus = createAsyncThunk('user/postGateWayStatus', async (payload) => {
  const response = await backendService.postGateWayStatus(payload);
  if (response.status === 200) { response.data = payload.params.id; }
  return response;
});

const slice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    // devicesLoading(state) {
    //   state.status = 'loading';
    // },
    // devicesLoaded(state, action) {
    //   const newDevice = {};
    //   action.payload.forEach((device) => {
    //     newDevice[device.id] = device;
    //   });
    //   state.devices = newDevice;
    //   state.status = 'idle';
    // },
    pauseTimeseriesPolling: (state, action) => {
      state.gatewayStatus.pausePolling = action.payload;
    },
    resetState: (state) => {
      state.assets = initialState.assets;
      state.devices = initialState.devices;
      state.gateways = initialState.gateways;
      state.gatewayStatus = initialState.gatewayStatus;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(assetByUser.fulfilled, (state, action) => {
        if (action.payload.status !== 'error' && action.payload !== undefined) {
          state.assets.push(action.payload);
        }
      })
      .addCase(gatewaysByAsset.fulfilled, (state, action) => {
        if (action.payload.status !== 'error') {
          state.gateways.push(action.payload.data);
        }
      })
      .addCase(devicesByCustomer.fulfilled, (state, action) => {
        if (action.payload.status !== 'error') {
          state.devices.push(action.payload.data);
          // state.gateways.push(action.payload.data);
        }
      })
      // .addCase(timeseriesKeys.fulfilled, (state, action) => {
      //   state.gatewayStatus = action.payload.data;
      // })
      .addCase(latestTimeseries.fulfilled, (state, action) => {
        if (action.payload.status !== 'error') {
          const data = { ...action.payload.data };
          if (data?.IntrusionDetected?.length > 0) {
            state.gatewayStatus.IntrusionDetected = data
              .IntrusionDetected[data.IntrusionDetected.length - 1].value;
          }

          if (data?.SecurityEnabled?.length > 0) {
            state.gatewayStatus.SecurityEnabled = data
              .SecurityEnabled[data.SecurityEnabled.length - 1].value;
          }

          state.gatewayStatus.pausePolling = false;
        }
      })
      .addCase(postGateWayStatus.fulfilled, (state, action) => {
        if (action.payload.status !== 'error') {
          state.gatewayStatus.SecurityEnabled = action.payload.data;
        }
      });
  },
});

export const { userDevices, pauseTimeseriesPolling, resetState } = slice.actions;

export default slice.reducer;
