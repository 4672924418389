import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import backendService from '../services/backend-services';
import { assetByUser, resetState } from './device-slice';

const initialState = {
  status: 'idle',
  error: '',
  profile: {
    id: null,
    username: null,
    firstName: null,
    lastName: null,
    loggedIn: false,
    accessToken: null,
    roles: [],
  },
};

/**
 *
 */
export const postUser = createAsyncThunk('user/signup', async (payload) => {
  const response = await backendService.postUser(payload);
  return response;
});

/**
 *
 */
export const userLoggedIn = createAsyncThunk('user/login', async (payload, thunkAPI) => {
  const response = await backendService.postLogin(payload);
  if (response.status !== 'error') {
    thunkAPI.dispatch(
      assetByUser(
        {
          userId: response.id,
          accessToken: response.accessToken,
        },
      ),
    );
  }
  return response;
});

/**
 *
 */
export const userLoggedOut = createAsyncThunk('user/logout', async (payload, thunkAPI) => {
  thunkAPI.dispatch(
    resetState(),
  );
});

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(postUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postUser.fulfilled, (state, action) => {
        state.profile = { ...action.payload.data };
        state.profile.loggedIn = true;
        state.status = 'idle';
      })
      .addCase(userLoggedIn.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userLoggedIn.fulfilled, (state, action) => {
        if (action.payload.status === 'error') {
          state.error = action.payload.data;
          state.status = action.payload.status;
          return;
        }

        // state.profile = { ...action.payload.data };
        state.profile.id = action.payload.id;
        state.profile.firstName = action.payload.firstName;
        state.profile.lastName = action.payload.lastName;
        state.profile.email = action.payload.email;
        state.profile.authToken = action.payload.accessToken;
        state.profile.roles = action.payload.roles;
        state.profile.loggedIn = true;
        state.status = 'idle';
        // state.status = action.payload.status === 'error' ? 'error' : 'idle';
      })
      .addCase(userLoggedOut.fulfilled, (state) => {
        storage.removeItem('persist:root');
        state.status = initialState.status;
        state.profile = initialState.profile;
        state.error = initialState.error;
      });
  },
});

export default slice.reducer;
