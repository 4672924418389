import React from 'react';
import { Box, Typography, Link } from '@mui/material';

export default function copyright() {
  return (
    <Box sx={{ bgcolor: '#eaeff1' }} component="footer">
      <Typography
        variant="subtitle1"
        align="center"
        color="text.secondary"
        component="p"
      >
        Footer
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://mui.com/">
          Your Website
        </Link>
        {' '}
        {new Date().getFullYear()}
        .
      </Typography>
    </Box>
  );
}
