import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import GppBadIcon from '@mui/icons-material/GppBad';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import HomeIcon from '@mui/icons-material/Home';
import SecurityIcon from '@mui/icons-material/Security';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { postGateWayStatus, latestTimeseries } from '../store/device-slice';

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

export default function dashboard() {
  const gatewayStatus = useSelector((state) => state.devices.gatewayStatus);
  const gateway = useSelector((state) => state.devices.gateways);
  const accessToken = useSelector((state) => state.user.profile.authToken);
  const dispatch = useDispatch();
  const delay = 10000;

  let securityEnabledStatus = 'Disarmed';

  if (gatewayStatus.SecurityEnabled === 1) {
    securityEnabledStatus = 'Home';
  } else if (gatewayStatus.SecurityEnabled === 2) {
    securityEnabledStatus = 'Away';
  }

  const cardActionHandler = (event) => {
    if (gateway?.length > 0) {
      dispatch(postGateWayStatus({
        entityId: gateway[0].deviceId,
        method: 'setSecurityAlarmOn',
        params: {
          label: event.currentTarget.ariaLabel,
          id: Number(event.currentTarget.id),
        },
        accessToken,
      }));
    }
  };

  useInterval(() => {
    if (gateway.length > 0 && gateway[0].deviceId) {
      dispatch(latestTimeseries({
        entityType: 'DEVICE',
        entityId: gateway[0].deviceId,
        accessToken,
      }));
    }
  }, !gatewayStatus.pausePolling ? delay : null);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
        },
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          width: 'fit-content',
          minWidth: 250,
        }}
      >
        <Card>
          <Grid container>
            <Grid item xs={6}>
              <CardActions>
                <IconButton id="0" aria-label="disarmed" onClick={cardActionHandler}>
                  <GppBadIcon color={gatewayStatus.SecurityEnabled === 0 ? 'success' : ''} />
                </IconButton>
                <IconButton id="1" aria-label="home" onClick={cardActionHandler}>
                  <HomeIcon color={gatewayStatus.SecurityEnabled === 1 ? 'success' : ''} />
                </IconButton>
                <IconButton id="2" aria-label="away" onClick={cardActionHandler}>
                  <DirectionsRunIcon color={gatewayStatus.SecurityEnabled === 2 ? 'success' : ''} />
                </IconButton>
              </CardActions>
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  {securityEnabledStatus}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Alert CO Alarm Garage Offiline
                </Typography>
              </CardContent>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Grid item xs>
              <CardContent>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={8}>
                    <Typography sx={{ mt: 1 }} variant="subtitle2" component="div">
                      Monitoring Status
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Chip
                      label={gatewayStatus.SecurityEnabled === 0 ? 'Off' : 'ON'}
                      color={gatewayStatus.SecurityEnabled === 0 ? 'error' : 'success'}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="subtitle2" component="div">
                      Monitored Sensors
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Chip label="0" color="primary" size="small" />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="subtitle2" component="div">
                      Active Cameras
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Chip label="1" color="primary" size="small" />
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          width: 'fit-content',
          minWidth: 200,
        }}
      >
        <Card>
          <CardActions>
            <IconButton color="primary">
              <SecurityIcon />
            </IconButton>
          </CardActions>
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              Protection Plan
            </Typography>
            <Typography variant="body2" color="text.secondary">
              View Status
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          width: 'fit-content',
          minWidth: 200,
        }}
      >
        <Card>
          <CardActions>
            <IconButton color="primary">
              <DynamicFeedIcon />
            </IconButton>
          </CardActions>
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              Neighbors
            </Typography>
            <Typography variant="body2" color="text.secondary">
              View Feed
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
