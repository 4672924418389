import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar, Grid, IconButton, Link,
  Tab, Tabs, Toolbar, Tooltip, Typography,
  Fade, Badge,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Popovermenu from './popovermenu';
import { pauseTimeseriesPolling } from '../store/device-slice';

export default function header(props) {
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.profile);

  //   const lightColor = 'rgba(255, 255, 255, 0.7)';

  const isLoggedIn = user.loggedIn;

  const handleTabChange = (event, newValue) => {
    const isRunning = newValue !== 0;
    if (isLoggedIn) dispatch(pauseTimeseriesPolling(isRunning));
    setActiveTab(newValue);
    props.handleTabChange(newValue);
  };

  return (
    <>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Typography color="inherit" variant="h5" component="h1">
                SmartSec
              </Typography>
            </Grid>
            <Grid item xs />
            {isLoggedIn
                        && (
                        <Grid item>
                          <Tooltip
                            title="Toggle notifications panel"
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                          >
                            <IconButton color="inherit">
                              <Badge badgeContent={5} color="secondary">
                                <NotificationsIcon />
                              </Badge>
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        )}
            {!isLoggedIn
                        && (
                        <Grid item>
                          <Tooltip
                            title="Login"
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                          >
                            <Link component={RouterLink} to="/login" variant="body2" color="inherit" underline="none">
                              Login
                            </Link>
                          </Tooltip>
                        </Grid>
                        )}
            <Grid item>
              <Tooltip
                title="Help"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
              >
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                title="Toggle to Cart"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
              >
                <IconButton color="inherit">
                  <ShoppingCartIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            {isLoggedIn
                        && (
                        <Grid item>
                          <Popovermenu />
                        </Grid>
                        )}
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0 }}>
        {!isLoggedIn
                && (
                <Tabs value={activeTab} textColor="inherit" onChange={handleTabChange}>
                  <Tab label="Products" />
                  <Tab label="Protect Plans" />
                </Tabs>
                )}
        {isLoggedIn
                && (
                <Tabs value={activeTab} textColor="inherit" onChange={handleTabChange}>
                  <Tab label="Dashboard" />
                  <Tab label="My Devices" />
                  <Tab label="Billing" />
                </Tabs>
                )}
      </AppBar>
    </>
  );
}

header.propTypes = {
  handleTabChange: PropTypes.func,
};
