import axios from 'axios';
import https from 'https';

import axiosRetry from 'axios-retry';

const AXIOS_API_TIMEOUT = 30000;
const AXIOS_RETRY_COUNT = 2;

const httpsAgent = new https.Agent({
  timeout: AXIOS_API_TIMEOUT,
  rejectUnauthorized: false,
  ca: `-----BEGIN CERTIFICATE-----
  MIIEATCCAumgAwIBAgIUagYbDVObyDzUQFe1tCq35vnsug4wDQYJKoZIhvcNAQEL
  BQAwgY8xCzAJBgNVBAYTAlVTMQswCQYDVQQIDAJDQTEQMA4GA1UEBwwHRnJlbW9u
  dDEOMAwGA1UECgwFRWppb3QxETAPBgNVBAsMCFNlY3VyaXR5MRkwFwYDVQQDDBBl
  amlvdHNlcnZpY2UuY29tMSMwIQYJKoZIhvcNAQkBFhRzaGFpbC5oaW1hQGdtYWls
  LmNvbTAeFw0yMjAzMDYwMzEwMjRaFw0yNzAzMDUwMzEwMjRaMIGPMQswCQYDVQQG
  EwJVUzELMAkGA1UECAwCQ0ExEDAOBgNVBAcMB0ZyZW1vbnQxDjAMBgNVBAoMBUVq
  aW90MREwDwYDVQQLDAhTZWN1cml0eTEZMBcGA1UEAwwQZWppb3RzZXJ2aWNlLmNv
  bTEjMCEGCSqGSIb3DQEJARYUc2hhaWwuaGltYUBnbWFpbC5jb20wggEiMA0GCSqG
  SIb3DQEBAQUAA4IBDwAwggEKAoIBAQCzUqJX3XCDD/Vm1PEfvI2RErWgkzDyl6PP
  Y823onk9nvLt1kuwapg1mNV++4JkPpdmwrCgKN7AYMlNdBJcPrgY2kvSM55V7G6A
  NKC5f6FMM1VN6HgfFKJfSWeFQPiYBqF4n3ERc5fdEkEBkDroTxzRzJQvzENgDERJ
  5ECaTdVsg6sp6NJQQcFUSONGfAsdmESV4Kbv+hvguLLC60JWmhiSp9OmleZzcBGR
  0SwrsDbLQhgR8FQsU4CNkW1Bdv9kJjbyw2cJC+rU2YKw4xGI2mzsEVDJwGCVLy3X
  EGkZCmH2BD35vR9i6fSuyBp7AlHitFoTZH1BHlHCRNwTnUYDOQqPAgMBAAGjUzBR
  MB0GA1UdDgQWBBThshiNhsMyrR6ItIV3uJNP3/GKQDAfBgNVHSMEGDAWgBThshiN
  hsMyrR6ItIV3uJNP3/GKQDAPBgNVHRMBAf8EBTADAQH/MA0GCSqGSIb3DQEBCwUA
  A4IBAQA0tyY8xd0RRsi9lXoARdePiyuNJrotD/RprphRy7xikbDFURQ7WAxbpJgj
  DNW04hnQB5UR+J+HnI0dR84AU5Y5f9Jnv3L9ONB7p5hR/+91YzBYJ0GMS/v+HxLs
  9NgLRgC+LNMkRt+D1a7btdnE/hMEZSw7SUJl9T9c69sic+weztWItnpXBW0CRcpC
  hrnLlhyovbd0sXP8B8/u3yq70/Tungj7zNj7htcntZD3hNT6WhM9jiQCELmP4IAo
  ptrq6R9L6y9XnuWk6sEg5XBZTMIvTllkUugp17nNE3CCXch/Q8c/7MMdIkgHKkRI
  +pBqv6ZV9G5OQyUc6iIfnu6tW8Rf
  -----END CERTIFICATE-----`,
});

const httpClient = axios.create({
  rejectUnauthorized: false,
  httpsAgent,
});

// httpClient.defaults.httpsAgent = httpsAgent;

axiosRetry(httpClient, { retries: AXIOS_RETRY_COUNT });

export default httpClient;
